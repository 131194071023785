<template>
  <div>
    <h4 class="font-weight-bolder text-body" style="margin-bottom: 10px">
      {{ $t('Business Information') }}
    </h4>
    <b-row>
      <b-col md="6">
        <div
          v-for="field in ['cust_name_dba']"
          :key="field"
          style="height: 70px"
        >
          <span class="inputLabel font-weight-bold">{{ fields[field].label }}</span>
          <b-skeleton
            height="38px"
            style="border-radius: 5px"
          />
        </div>
        <div style="height: 72px">
          <span class="font-weight-bolder">{{ 'Status' }}</span>
          <div class="d-flex align-items-center justify-content-between">
            <span class="ml-1">{{ fields['is_active'].label }}</span>
            <b-skeleton
              width="20px"
              height="20px"
              style="border-radius: 5px; margin: 0"
            />
          </div>
        </div>
        <div
          v-for="field in ['account_manager_id', 'payment_term_id', 'price_tier_id', 'tax_rule_id', 'tags']"
          :key="field"
          style="height: 70px"
        >
          <span class="inputLabel font-weight-bold">{{ fields[field].label }}</span>
          <b-skeleton
            height="38px"
            style="border-radius: 5px"
          />
        </div>
        <div class="mb-1">
          <span class="inputLabel font-weight-bold">{{ fields['comments'].label }}</span>
          <div
            class="border d-flex flex-column"
            style="padding: 15px; border-radius: 5px"
          >
            <b-skeleton
              height="18px"
              width="50%"
              style="border-radius: 5px"
            />
            <b-skeleton
              height="18px"
              width="90%"
              style="border-radius: 5px"
            />
            <b-skeleton
              width="80%"
              height="18px"
              style="border-radius: 5px"
            />
            <b-skeleton
              width="85%"
              height="18px"
              style="border-radius: 5px"
            />
            <b-skeleton
              width="60%"
              height="18px"
              style="border-radius: 5px"
            />
            <b-skeleton
              height="18px"
              style="border-radius: 5px"
            />
            <b-skeleton
              height="18px"
              style="border-radius: 5px"
            />
          </div>
        </div>
        <span
          class="font-weight-bolder d-inline-block"
          style="margin-bottom: 10px"
        >
          {{ $t('Payment options') }}
        </span>
        <div
          v-for="item in Array(5)"
          :key="item"
          class="d-flex align-items-center justify-content-between"
          style="margin: 5px 5px 10px 10px"
        >
          <b-skeleton
            class="mb-0"
            height="18px"
            style="border-radius: 5px; margin-left: 4px; min-width: 180px !important;"
          />
          <b-skeleton
            class="mb-0"
            height="18px"
            style="border-radius: 5px; margin-left: 4px; min-width: 18px !important;"
          />
        </div>
        <span
          class="font-weight-bolder d-inline-block"
          style="margin-bottom: 10px"
        >
          {{ $t('Customer Rules') }}
        </span>
        <div
          class="d-flex align-items-center justify-content-between"
          style="margin: 5px 5px 10px 10px"
        >
          <b-skeleton
            class="mb-0"
            height="18px"
            style="border-radius: 5px; margin-left: 4px; min-width: 180px !important;"
          />
          <b-skeleton
            class="mb-0"
            height="18px"
            style="border-radius: 5px; margin-left: 4px; min-width: 18px !important;"
          />
        </div>
      </b-col>
      <b-col md="6">
        <div
          v-for="field in ['pseudonym', 'currency_id', 'delivery_type_id', 'location_id', 'ein_tax_number', 'duns_number']"
          :key="field"
          style="height: 70px"
        >
          <span class="inputLabel font-weight-bold">{{ fields[field].label }}</span>
          <b-skeleton
            height="38px"
            style="border-radius: 5px"
          />
        </div>
        <div style="height: 72px" />
        <div
          v-for="field in ['discount', 'credit_limit']"
          :key="field"
          style="height: 70px"
        >
          <span class="inputLabel font-weight-bold">{{ fields[field].label }}</span>
          <b-skeleton
            height="38px"
            style="border-radius: 5px"
          />
        </div>
        <span
          class="font-weight-bolder d-inline-block"
          style="margin-bottom: 10px"
        >
          {{ $t('Business Transactions') }}
        </span>
        <div
          v-for="item in Array(5)"
          :key="item"
          class="d-flex align-items-center justify-content-between"
          style="margin: 5px 5px 10px 10px"
        >
          <b-skeleton
            class="mb-0"
            height="18px"
            style="border-radius: 5px; margin-left: 4px; min-width: 180px !important;"
          />
          <b-skeleton
            class="mb-0"
            height="18px"
            style="border-radius: 5px; margin-left: 4px; min-width: 18px !important;"
          />
        </div>
      </b-col>
    </b-row>
  </div>

</template>
<script>
import {
  BRow, BCol, BSkeleton,
} from 'bootstrap-vue'
import config from '../customConfig'

export default {
  name: 'Skeleton',
  components: {
    BCol,
    BRow,
    BSkeleton,
  },
  setup() {
    const {
      primaryContactInformation,
      accountParameters,
      moreContactInformation,
    } = config()
    const fields = { ...accountParameters, ...primaryContactInformation, ...moreContactInformation }
    return {
      fields,
    }
  },
}
</script>
