<template>
  <div>
    <error-alert
      :fields="{ fields }"
      :error="error"
    />
    <portal to="body-top">
      <customer-name title="- update a Business Entity" />
    </portal>
    <div>
      <skeleton v-if="isFetching" />
      <update-general-info-form
        v-else
        ref="update-general-info-form"
        @formSubmissionComplete="handleFormSubmissionComplete"
      />
    </div>
  </div>
</template>

<script>
import ErrorAlert from '@/views/components/ErrorAlert.vue'
import mainCustomersMenuItems from '@/views/components/sub-menu/main-sub-menu/home-customers'
import UpdateGeneralInfoForm from '@/views/main/customers/components/CustomerForm/UpdateGeneralInfoForm.vue'
import CustomerName from '../../components/CustomerName.vue'
import config from '../../customConfig'
import Skeleton from '../../components/Skeleton.vue'

export default {
  name: 'GeneralInfo',
  components: {
    Skeleton,
    UpdateGeneralInfoForm,
    ErrorAlert,
    CustomerName,
  },
  data() {
    return {
      error: {},
      isFetching: false,
      defaults: null,
    }
  },
  computed: {
    customer() {
      return this.$store.state[this.MODULE_NAME].customer
    },
  },
  created() {
    this.$emit('updateMenu', 'home-customers-general-info')

    const { data } = { data: this.customer }
    const merge = {
      ...data,
      ...data.business_transaction,
      ...data.customer_detail,
    }

    const accountParameters = this.mappingFields(Object.keys(this.accountParameters), merge, {
      id: merge.id,
      is_active: data.is_active,
      account_manager_id: merge.account_manager,
      payment_term_id: merge?.payment_terms?.length ? merge?.payment_terms?.map(tag => tag.label) : [],
      payment_term_ids: merge.payment_term_ids,
      payment_methods: merge.payment_method_ids,
      payment_method_id: merge?.payment_methods?.length ? merge?.payment_methods?.map(tag => tag.name) : [],
      price_tier_id: merge.price_tier,
      tax_rule_id: merge.tax_rule,
      tags: merge.tags ? merge.tags.map(tag => tag.label) : [],
      comments: merge.comments,
      customer_rules_ids: merge.customer_rules.map(rule => rule.id),
      currency_id: merge.currency,
      location_id: merge.location,
      delivery_type_id: merge.delivery_type,
      duns_number: merge.duns_number,
    })
    const {
      cust_name_dba,
      pseudonym,
    } = merge
    this.$store.commit(`${this.MODULE_NAME}/SET_ACCOUNT_PARAMETERS_FORM`, {
      ...accountParameters,
      cust_name_dba,
      pseudonym,
    })
    // this.$store.commit(`${this.MODULE_NAME}/SET_PRIMARY_CONTACT_INFORMATION_FORM`, cust_name_dba)
    this.$store.commit(`${this.MODULE_NAME_CLONE}/SET_CUSTOMER_FORM_CLONE`, {
      ...accountParameters,
      cust_name_dba,
      pseudonym,
    })
    // Clone data
    // this.$store.commit(`${this.MODULE_NAME_CLONE}/SET_CUSTOMER_FORM_CLONE`, customer)
    // this.$store.commit(`${this.MODULE_NAME_CLONE}/SET_ACCOUNT_HOLDER_CLONE`, accountHolder)
  },
  methods: {
    handleFormSubmissionComplete(submittedData) {
      this.$store.commit(`${this.MODULE_NAME_CLONE}/SET_CUSTOMER_FORM_CLONE`, submittedData)
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.$refs['update-general-info-form']) this.$refs['update-general-info-form'].showConfirmationModal(next)
  },
  setup() {
    const MODULE_NAME = 'customers'
    const MODULE_NAME_CLONE = 'cloneData'
    const { menuItems } = mainCustomersMenuItems('home-customers-create', {}, true)
    const { primaryContactInformation, accountParameters, primaryAddressInformation } = config()

    const fields = {
      ...primaryContactInformation,
      ...accountParameters,
      ...primaryAddressInformation,
    }

    return {
      menuItems,
      MODULE_NAME,
      MODULE_NAME_CLONE,
      fields,
      accountParameters,
      primaryContactInformation,
      primaryAddressInformation,
    }
  },
}
</script>

<style scoped>

</style>
