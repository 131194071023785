<template>
  <div>
    <b-modal
      :id="id"
      hide-header
      centered
      body-class="l-modal__body"
      footer-class="l-modal-footer"
      ok-title="Not the Same"
      ok-variant="secondary"
      cancel-variant="outline-primary"
      cancel-title="It’s the same"
      @ok="$emit('ok')"
      @cancel="$emit('cancel', existCustomer.id)"
      @hide="$emit('hide')"
    >
      <div class="l-modal__container-p25">
        <b-row>
          <b-col cols="12">
            <h3 class="l-modal__title">
              It looks like this company name is already in the system:
            </h3>
          </b-col>
          <b-col cols="6">
            <h5 class="font-weight-bolder">
              Company Name / DBA
            </h5>
            <div class="d-flex align-items-center">
              <p class="m-0">{{ existCustomer
                && existCustomer.cust_name_dba }}</p>
              <b-alert
                v-if="existCustomer && !existCustomer.is_active"
                class="m-0 ml-1"
                style="padding: 3px;"
                variant="danger"
                show
              >Inactive</b-alert>
            </div>
          </b-col>
          <b-col cols="6">
            <h5 class="font-weight-bolder">
              Pseudonym
            </h5>
            <p>{{ existCustomer &&
              existCustomer.customer_detail &&
              existCustomer.customer_detail.pseudonym || 'Not found' }}</p>
          </b-col>
          <b-col cols="6">
            <h5 class="font-weight-bolder">
              Associated Addresses:
            </h5>
            <p>{{ existCustomer &&
              existCustomer.customer_detail &&
              existCustomer.customer_detail.location &&
              existCustomer.customer_detail.location.label }}</p>
          </b-col>
          <b-col cols="6">
            <h5 class="font-weight-bolder">
              Associated Contacts:
            </h5>
            <p>{{ `${existCustomer &&
              existCustomer.default_contact &&
              existCustomer.default_contact.first_name} ${existCustomer &&
              existCustomer.default_contact &&
              existCustomer.default_contact.last_name}  ${existCustomer&&
              existCustomer.default_contact &&
              existCustomer.default_contact.phone_no}` }}</p>
          </b-col>
          <b-col
            cols="6"
          >
            <h5 class="font-weight-bolder">
              Companies Found:
            </h5>
            <p>{{ existCustomersLength }}</p>
          </b-col>
          <b-col
            cols="6"
            class="d-flex justify-content-end align-items-center"
          ><b-button
             size="sm"
             class="mr-1"
             :disabled="0 === currentIdx"
             @click="currentIdx--"
           >
             <feather-icon
               icon="LArrowUpLineIcon"
               size="16"
               class="mr-0"
               style="transform: rotate(-90deg); color: white"
             />
           </b-button>
            <b-button
              size="sm"
              :disabled="existCustomersLength === currentIdx + 1"
              @click="currentIdx++"
            >
              <feather-icon
                icon="LArrowUpLineIcon"
                size="16"
                class="mr-0"
                style="transform: rotate(90deg); color: white"
              />
            </b-button>
          </b-col>
        </b-row>
      </div>
    </b-modal>
  </div>
</template>
<script>
import {
  BButton, BCol, BRow, BModal, BAlert,
} from 'bootstrap-vue'

export default {
  components: {
    BButton, BCol, BRow, BModal, BAlert,
  },
  props: {
    existCustomers: {
      type: Array,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      currentIdx: 0,
    }
  },
  computed: {
    existCustomer() {
      return this.existCustomers[this.currentIdx]
    },
    existCustomersLength() {
      return this.existCustomers.length
    },
  },
  methods: {
    show() {
      this.$bvModal.show(this.id)
    },
    hide() {
      this.$bvModal.hide(this.id)
    },
  },
}
</script>
